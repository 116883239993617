// extracted by mini-css-extract-plugin
export var caption = "Teaser-module--caption--BWGbf";
export var customBorder = "Teaser-module--customBorder--CkXJF";
export var customContainer = "Teaser-module--customContainer--SqmcV";
export var description = "Teaser-module--description--oaTxU";
export var headline = "Teaser-module--headline--2eEm0";
export var headlineCol = "Teaser-module--headlineCol---U9eM";
export var image = "Teaser-module--image--J-v6U";
export var imageOnTop = "Teaser-module--imageOnTop--IJ2mw";
export var listDescription = "Teaser-module--listDescription--dG4+b";
export var listItem = "Teaser-module--listItem--Z+suu";
export var paddingLeft = "Teaser-module--paddingLeft--7B1CQ";
export var paddingRight = "Teaser-module--paddingRight--WsYqx";