import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './Teaser.module.scss';
import "../../swiperStuff.scss"
import "./swiper.scss"
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import * as mainStyle from "../../index.module.scss";

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


type TeaserProps = {
    title: string,
    caption: string,

    image1: string,
    image2: string,
    image3: string,

    listItem1Description: string,
    listItem2Description: string,
    listItem3Description: string,
    listItem1VendorTitle: string,
    listItem2VendorTitle: string,
    listItem3VendorTitle: string,
}


function Teaser(props: TeaserProps) {
    const breakpoints = {
        991: {
            slidesPerView: 3
        },
        0: {
            slidesPerView: 1
        }
    };
    return <>
        <div className={style.customContainer}>
            <Container>
                <Row className={mainStyle.box}>
                    <Col lg={12} className={style.headlineCol}>
                        <div>
                            <div className={style.caption}>
                                <div className={style.caption}>{props.caption}</div>
                            </div>
                            <div className={style.headline}>
                                <p>{props.title}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <Swiper
                            navigation={true}
                            slidesPerView={3}
                            modules={[Navigation]}
                            breakpoints={breakpoints}
                            className={style.mySwiper + " tableSwiper"}
                        >
                            <SwiperSlide>
                                <div>
                                    <img src={props.image1} className={style.image}/>
                                </div>
                                <div className={style.description}>{props.listItem1Description} <br/><br/><b>{props.listItem1VendorTitle}</b></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={props.image2} className={style.image}/>
                                    <div className={style.description}>{props.listItem2Description} <br/><br/><b>{props.listItem2VendorTitle}</b></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src={props.image3} className={style.image}/>
                                    <div className={style.description}>{props.listItem3Description} <br/><br/><b>{props.listItem3VendorTitle}</b></div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
        ;
}

export default Teaser;
